.card {
    border-radius: $border-radius-none;
    background-color: $white-color;
    margin-bottom: 30px;

    .card-image {
        width: 100%;
        overflow: hidden;
        height: 260px;
        border-radius: $border-radius-base $border-radius-base 0 0;
        position: relative;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;

        img {
            width: 100%;
        }
    }

    .filter {
        position: absolute;
        z-index: 2;
        background-color: rgba(0, 0, 0, .68);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        @include opacity(0);

        .btn {
            @include vertical-align();
        }
    }

    &:hover .filter {
        @include opacity(1);
    }

    .btn-hover {
        @include opacity(0);
    }

    &:hover .btn-hover {
        @include opacity(1);
    }

    .card-body {
        padding: 15px 15px 10px 15px;
    }

    .card-header {
        padding: 15px 15px 0;
        background-color: $white-color;
        border-bottom: none !important;

    }

    .card-category,
    label {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        color: $dark-gray;
        margin-bottom: 0px;

        i {
            font-size: $font-paragraph;
        }
    }

    label {
        font-size: $font-size-small;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    .card-title {
        margin: $none;
        color: $black-color;
        font-weight: $font-weight-bold;
    }

    .avatar {
        width: 25px;
        height: 25px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 5px;
    }

    .description {
        font-size: $font-size-base;
        color: #333;
    }

    .card-footer {
        padding-top: 0;
        background-color: $transparent-bg;
        line-height: 30px;
        border-top: none !important;
        font-size: 14px;

        .legend {
            padding: 5px 0;
        }

        hr {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .stats {
        color: #a9a9a9;
    }

    .card-footer div {
        display: inline-block;
    }

    .author {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .author i {
        font-size: $font-size-base;
    }

    h6 {
        font-size: $font-size-small;
        margin: 0;
    }

    &.card-separator:after {
        height: 100%;
        right: -15px;
        top: 0;
        width: 1px;
        background-color: $medium-gray;
        card-body: "";
        position: absolute;
    }

    .ct-chart {
        margin: 30px 0 30px;
        height: 245px;
    }

    .ct-label {
        font-size: 1rem !important;
    }

    .table {

        tbody td:first-child,
        thead th:first-child {
            padding-left: 15px;
        }

        tbody td:last-child,
        thead th:last-child {
            padding-right: 15px;
        }
    }

    .alert {
        border-radius: $border-radius-base;
        position: relative;

        &.alert-with-icon {
            padding-left: 65px;
        }
    }
}

.table-card {
    .card-header {
        padding: 20px 0 !important;
        border-bottom: 1px solid $medium-gray !important;
    }

    .card-title,
    p,
    a {
        padding: 0px 20px;
    }
}

.card-stats {
    border: 2px dotted $primary-color;

    .card-body {
        padding: 30px;

        img.icon-summery {
            max-height: 80px;
        }

        .numbers {
            font-size: 1.8rem;
            text-align: left;

            .card-category {
                font-weight: bold;
                color: $primary-color;
            }

            h4 {
                font-weight: bold;
                font-size: 2rem;
            }

            p {
                margin-bottom: 6px;
            }
        }
    }

    .card-footer {
        padding: 0px 15px 10px 15px;


    }

    .icon-big {
        font-size: 3em;
        min-height: 64px;

        i {
            font-weight: 700;
            line-height: 59px;
        }
    }


}

.card-user {
    .card-image {
        height: 110px;
    }

    .card-image-plain {
        height: 0;
        margin-top: 110px;
    }

    .author {
        text-align: center;
        text-transform: none;
        margin-top: -70px;
    }

    .avatar {
        width: 124px;
        height: 124px;
        border: 5px solid #FFFFFF;
        position: relative;
        margin-bottom: 15px;

        &.border-gray {
            border-color: #EEEEEE;
        }
    }

    .title {
        line-height: 24px;
    }

    .card-body {
        min-height: 240px;
    }
}

.card-user,
.card-price {
    .card-footer {
        padding: 5px 15px 10px;
    }

    hr {
        margin: 5px 15px;
    }
}

.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;

    .card-image {
        border-radius: 4px;
    }
}

.card.card-plain {
    border: none !important;

    .card-header {
        background-color: transparent !important;
    }
}

ul.pagination {
    display: flex;
    align-items: center;

    li.page-item {

        a.page-link,
        span {
            border: 0;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px !important;
            margin: 0 1px !important;
            color: $black-color;

            &.active {
                border: 0;
                padding: 4px 5px;
                margin: 0 !important;

                .page-link {
                    background-color: $primary-color;
                }
            }

        }

        &:first-child,
        &:last-child {
            font-size: 1.35rem;
        }
    }

}

.inline-select {
    display: flex !important;
    align-items: center;

    .form-label {
        font-size: 1rem !important;
        color: $black-color;
    }
}

.services-card {
    border-bottom: 0;

    h4.card-title {
        font-size: 18px;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
        font-weight: bold;

    }

    .card-body {
        background-color: #dee4eb;

        padding: 0;

        h4 {
            font-size: 15px;
            font-weight: $font-weight-bold;
            padding: 4px 16px;
            margin: 0;
            margin-bottom: 0px;
            background-color: #EEF3F8;
            border-bottom: 1px solid #D2D8DF;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                border-bottom: 1px solid #dee4eb;
                background-color: #fff;
                padding: 5px 12px;
                font-size: $font-size-base;
            }
        }

    }

    &.card-inside {
        border: 0;

        .card-body {
            h4.main-catagory {
                font-weight: $font-weight-bold;
                font-size: 1rem;
            }

            ul {
                li {
                    display: flex;
                    align-items: center;
                }
            }
        }

    }

    &.stylist-services {
        .card-body {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}