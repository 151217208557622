.table {
  .radio,
  .checkbox {
    position: relative;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0px 0px;
    margin: 0px 5px;
    text-align: center;

    .icons {
      left: 5px;
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 8px;
    vertical-align: middle;
    font-size: 15px;
  }

  > thead > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: $font-weight-bold;
    padding-bottom: 5px;
    border-top: none !important;
    border-bottom: none;
    text-align: left !important;
  }

  .td-actions .btn {
    @include opacity(0.36);

    &.btn-xs {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .td-actions {
    min-width: 90px;
  }

  > tbody > tr {
    position: relative;

    &:hover {
      .td-actions .btn {
        @include opacity(1);
      }
    }
  }

  .btn:focus {
    box-shadow: none !important;
  }
}
.table-upgrade {
  .table {
    tr {
      td {
        width: 100%;
      }
    }
  }
}

.table .form-check .form-check-sign,
.table .form-check .form-check-sign:after,
.table .form-check .form-check-sign:before {
  margin-top: -16px;
}

.table-type01 tbody > tr > td {
  padding: 8px !important;
  a {
    padding: 0;
    color: $primary-color !important;
  }
  .badge-warning {
    background-color: #ffe7cf;
    color: #ffc107;
    padding: 8px 10px !important;
    font-size: 0.9rem;
    font-weight: normal !important;
  }
  .badge-rejected {
    background-color: #ff4d0a;
    color: #ffe7cf;
    padding: 8px 10px !important;
    font-size: 0.9rem;
    font-weight: normal !important;
  }
  .badge-pending {
    background-color: #ffe7cf;
    color: #ff4d0a;
    padding: 8px 10px !important;
    font-size: 0.9rem;
    font-weight: normal !important;
  }
  .badge-active {
    background-color: #e3f6ef;
    color: #00ae6d;
    padding: 8px 10px !important;
    font-size: 0.9rem;
    font-weight: normal !important;
  }
}
.td-user-thumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img.user-40 {
    width: 38px;
    height: 38px;
    border-radius: 40px;
    margin-right: 10px;
  }
  p {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.service-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px;
    height: 25px;
    border: 1px solid $dark-gray;
    border-radius: 25px;
    margin-right: 6px;
    &.at-home {
      background-image: url("../../img/ic_home.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.at-salon {
      background-image: url("../../img/ic_salon.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
.badge-active {
  background-color: #e3f6ef;
  color: #00ae6d;
  padding: 8px 10px !important;
  font-size: 0.9rem;
  font-weight: bold !important;
}

.badge-warning {
  background-color: #ffe7cf;
  color: #ffc107;
  padding: 8px 10px !important;
  font-size: 0.9rem;
  font-weight: normal !important;
}
.badge-rejected {
  background-color: #ff4d0a;
  color: #ffe7cf;
  padding: 8px 10px !important;
  font-size: 0.9rem;
  font-weight: normal !important;
}
.badge-pending {
  background-color: #ffe7cf;
  color: #ff4d0a;
  padding: 8px 10px !important;
  font-size: 0.9rem;
  font-weight: normal !important;
}

.badge-active-phone {
  color: #00ae6d;
  padding: 8px 10px !important;
  font-size: 0.9rem;
  font-weight: bold !important;
}
.scrollme {
  overflow-x: 100px;
}
