/*     General overwrite     */
body,
.wrapper {
    min-height: 100vh;
    position: relative;
}

a {
    color: $info-color;

    &:hover,
    &:focus {
        color: $info-states-color;
        text-decoration: none;
    }
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
    outline: 0;
}

.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
    outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
    @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table>tbody>tr .td-actions .btn {
    @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    width: 18px;
    text-align: center;
}

.margin-top {
    margin-top: 50px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

// documentation

.page-header {
    .page-header-image {
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        z-index: 1;
    }

    .title-container {
        color: #fff;
        position: relative;
        top: 250px;
        z-index: 3;
    }

    .filter:after {
        background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #9368e9 0%, #943bea 100%) repeat scroll 0 0 / 150% 150%;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.77;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
}

.documentation .page-header,
.documentation .page-header-image,
.documentation .page-header-image .filter:after {
    height: 100vh;
}

.documentation .footer {
    z-index: 3;
}

.documentation .wrapper {
    margin-top: -61px;
    height: 100vh;
}

.documentation .navbar {
    z-index: 21;
}

.stylist_list {
    display: flex;
    border-bottom: 1px solid $medium-gray;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 8px;

    img.user-60 {
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }

    p {
        padding: 0;
        margin: 0;
        padding-bottom: 2px;
        font-size: .85rem;

        &.username {
            font-weight: bold;
            font-size: 1rem;
        }

        .fa-star {
            color: #FFD91D;
            font-size: 1rem;
        }
    }
}

.avatar-100 {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.avatar-70 {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
}

.login-bg {
    background-image: url(../../img/bg_login.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: 100vh;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($primary-color, 0.9);
        height: 100%;

    }
}

.login-wrapper {
    border-radius: 8px;
    background-color: $white-bg;
    color: $black-bg;
    max-width: 500px;
    margin: 0 auto;
}

.text-sm {
    font-size: 14px;
}

.card-header-tabs {
    border-bottom: 1px solid #D2D8DF;

    a.nav-link {
        border: none;
        color: $primary-color;
        min-width: 180px;
        text-align: center;

        &.active {
            border: none;
            color: $primary-color;
            border-bottom: 4px solid #2185FF !important;
        }
    }
}

ul.order-summary {
    padding-left: 0;

    li {
        margin: 0;
        padding-bottom: 4px;
        list-style: none;
    }
}

ul.list-styled {
    padding-left: 0;

    li {
        margin: 0;
        padding-bottom: 4px;
        padding-left: 25px;
        position: relative;

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: black;
            position: absolute;
            left: 0;
            top: 5px;
            right: 0;
            bottom: 0;
        }
    }
}

.overflow-scrolling {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 1060px;
}

.btn-sucess {
    background-color: #00AE6D !important;
}

.border-dotted-bottom {
    border-bottom: 1px dashed #D2D8DF;
}

.notofication-list {
    padding: 15px !important;
    padding-left: 45px !important;
    font-size: 14px !important;
    position: relative;
    &::before{
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $primary-color;
        position: absolute;
        left: 20px;
        top: 26px;
    }
}